<template>
  <Page class="page-items page-list-items">
    <template #title>Offerte scadute</template>

    <template #default>
      <template v-if="items && items.length > 0">
        <div class="row">
          <div class="col-sm-12 col-lg-6 col-xl mb-4" v-for="item in items" :key="item.id">
            <OfferCard :offer="item" />
          </div>
        </div>

        <div class="row justify-content-end align-items-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessuna offerta scaduta</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import rolesMixin from '@/mixins/roles.js';

import Page from '@/views/components/Private/Page.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import PerPageSelector from '@/libs/Pagination/components/PerPageSelector.vue';
import OfferCard from '@/views/components/Cards/OfferCard.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listItems(null, null, { onlyExpired: true })
    .then(res => next(vm => {
      vm.items = res?.data?.items || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    Page,
    AwlPagination,
    PerPageSelector,
    OfferCard,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({ items: [] }),
  methods: {
    fetchResults (page = this.page) {
      return $api.listItems(page, this.perPage, { onlyExpired: true })
        .then(res => {
          this.items = res?.data?.items || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
