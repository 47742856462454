<template>
  <div class="offer-card__container mb-5">
    <article class="offer-card d-block shadow" :style="headerStyle">
      <h3 class="offer-card__title">
        <span class="text-uppercase">{{ offer.name }}</span>
        <span v-if="offer.sellableUntilFormatted || offer.sellableSinceFormatted" class="d-block mt-2 mb-0 text-secondary text-h6">
          <template v-if="offer.sellableUntilFormatted && offer.sellableSinceFormatted">Valido dal {{ offer.sellableSinceFormatted }} al {{ offer.sellableUntilFormatted }}</template>
          <template v-else-if="offer.sellableUntilFormatted">Valido fino al {{ offer.sellableUntilFormatted }}</template>
          <template v-else-if="offer.sellableSinceFormatted">Valido dal {{ offer.sellableSinceFormatted }}</template>
        </span>
      </h3>

      <p class="offer-card__price shadow">
        <template v-if="offer.priceVariable">Prezzo dinamico</template>
        <template v-else>{{ offer.price | currency }}</template>
      </p>
    </article>
    <div class="d-flex justify-content-between">
      <router-link class="btn btn-outline-primary mt-3 mr-3" :to="{ name: showItemRouteName, params: { id: offer.id } }">Visualizza</router-link>
      <router-link class="btn btn-outline-secondary mt-3" :to="{ name: editItemRouteName, params: { id: offer.id } }">Modifica</router-link>
    </div>
  </div>
</template>

<script>

import formatCurrency from '@/filters/formatCurrency.js';
import rolesMixin from '@/mixins/roles.js';

export default {
  mixins: [rolesMixin],
  filters: { currency: formatCurrency },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headerStyle () {
      const headerStyle = {};

      if (this.offer?.cover?.url) {
        headerStyle['background-image'] = 'url(' + this.offer.cover.url + ')';
      } else if (this.offer?.shop?.cover?.url) {
        headerStyle['background-image'] = 'url(' + this.offer.shop.cover.url + ')';
      } else {
        headerStyle['background-image'] = 'url(/item_default_image.png)';
      }

      return headerStyle;
    },
    showItemRouteName () {
      if (!this.isAdmin && this.offer.isComing) {
        return 'items.coming.show';
      }

      if (!this.isAdmin && this.offer.isExpired) {
        return 'items.expired.show';
      }

      return 'items.show';
    },
    editItemRouteName () {
      if (!this.isAdmin && this.offer.isComing) {
        return 'items.coming.edit';
      }

      if (!this.isAdmin && this.offer.isExpired) {
        return 'items.expired.edit';
      }

      return 'items.edit';
    },
  },
};

</script>

<style lang="scss">

.offer-card {
  background-color: lighten($ezicommerce-darkgrey, 30);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  height: 230px;
  position: relative;

  &__container {
    @include media-breakpoint-up("xl") {
      min-width: 435px;
      width: 435px;
      max-width: 435px;
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background: rgba(darken($ezicommerce-darkgrey, 10), .8);
    font-size: 18px;
    padding: 5px 15px;
  }

  &__price {
    background: $ezicommerce-red;
    color: $ezicommerce-white;
    font-weight: bold;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 15px;
    margin-top: -10px;
    padding: 5px 10px;
  }
}

</style>
